// @flow

import { useQuery, } from 'react-apollo';
import { useRouter, } from 'next/router';
import { FINANCE_QUERY, } from '@haaretz/graphql';

type AccountDataGetterProps = {
  children: Function,
}

export default function FinanceDataGetter({ children, }: AccountDataGetterProps) {
  const router = useRouter();
  const path = (router && router.query.path) || '/';
  const variables = router.query.previewId ? { id: router.query.previewId, } : { path, };

  const { error, loading, } = useQuery(FINANCE_QUERY, { variables, });

  if (loading) return null;
  if (error) return null;

  return children;
}
