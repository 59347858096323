// @flow
import React from 'react';
import type { Node, } from 'react';
import Head from 'next/head';
import config from 'config';
import ApolloConsumer from '../ApolloBoundary/ApolloConsumer';

type Props = {
  title: string,
  description: string,
  path: string,
};

function SeoData({ title, description, path, }: Props): Node {
  const useSSL: boolean = config.get('useSSL');
  const hostname: string = config.get('hostname');
  const domain: string = config.get('domain');
  const canonicalUrl: string = path?.startsWith('/') ? `http${useSSL ? 's' : ''}://${hostname}.${domain}${path}` : path;
  return (
    <ApolloConsumer>
      {client => {
        client.writeData({
          data: {
            canonicalUrl,
            title,
          },
        });
        return (
          <Head>
            <title>{title}</title>
            <meta
              name="description"
              content={description}
              key="description"
            />
            <link
              rel="canonical"
              href={canonicalUrl}
            />
          </Head>
        );
      }}
    </ApolloConsumer>
  );
}

export default SeoData;
