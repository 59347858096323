// @flow
import React from 'react';
import Link from 'next/link';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { FelaComponent, } from 'react-fela';

import type { Node, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';

type Props = {
  miscStyles?: ?StyleProps,
  section?: ?string,
};

type ListItemProps = {
  href:
    | string
    | {
    pathname: string,
    query: Object,
  },
  as: ?string,
  name: string,
  selected: boolean,
  color: ?string[],
};

ListItem.defaultProps = {
  as: null,
  color: null,
};

function ListItem({ name, as, href, selected, color, }: ListItemProps): Node {
  return (
    <FelaComponent
      style={({ theme, }) => ({
        ...(selected
          ? {
            color: theme.color('primary'),
            textDecoration: 'underline',
          }
          : color
            ? { color: theme.color(color[0], color[1]), }
            : {}
        ),
        display: 'inline-block',
        marginEnd: '4rem',
        position: 'relative',
        ':hover': {
          textDecoration: 'underline',
        },
        ':not(:last-child)': {
          ':after': {
            backgroundColor: theme.color('neutral', '-3'),
            bottom: '50%',
            content: '""',
            end: '-2rem',
            height: '75%',
            position: 'absolute',
            transform: 'translate(-50%, 50%)',
            width: '2px',
          },
        },
      })}
      as="li"
    >
      <Link href={href} as={as}>
        <a>{name}</a>
      </Link>
    </FelaComponent>
  );
}

const baseQueryParams = {
  path: '/finance',
};

const NavigationBar = ({ section, miscStyles, }: Props): Node => (
  <FelaComponent
    style={({ theme, }) => ({
      ...theme.type(-1),
      backgroundColor: theme.color('neutral', '-10'),
      color: theme.color('neutral', '-3'),
      fontWeight: '700',
      paddingBottom: '2rem',
      paddingTop: '2rem',
      textAlign: 'center',
      extend: [
        ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
      ],
    })}
    as="ul"
  >
    <ListItem
      selected={!!(section && section.toLowerCase() === 'index')}
      href={{
        pathname: '/',
        query: {
          ...baseQueryParams,
          section: 'index',
        },
      }}
      as="/"
      name="ראשי"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'indices')}
      href={{
        pathname: '/section/indices',
        query: {
          ...baseQueryParams,
          section: 'indices',
        },
      }}
      as="/indices"
      name="מדדים"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'stock')}
      href={{
        pathname: '/section/stock',
        query: {
          ...baseQueryParams,
          section: 'stock',
        },
      }}
      as="/stock"
      name="מניות"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'exchange')}
      href={{
        pathname: '/section/exchange',
        query: {
          ...baseQueryParams,
          section: 'exchange',
        },
      }}
      as="/exchange"
      name="מט&quot;ח"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'crypto')}
      href={{
        pathname: '/section/crypto',
        query: {
          ...baseQueryParams,
          section: 'crypto',
        },
      }}
      as="/crypto"
      name="מטבעות דיגיטליים"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'options')}
      href={{
        pathname: '/section/options',
        query: {
          ...baseQueryParams,
          section: 'options',
        },
      }}
      as="/options"
      name="אופציות"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'bond')}
      href={{
        pathname: '/section/bond',
        query: {
          ...baseQueryParams,
          section: 'bond',
        },
      }}
      as="/bond"
      name="אג&quot;ח"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'mtf')}
      href={{
        pathname: '/section/mtf',
        query: {
          ...baseQueryParams,
          section: 'mtf',
        },
      }}
      as="/mtf"
      name="קרנות נאמנות"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'etf')}
      href={{
        pathname: '/section/etf',
        query: {
          ...baseQueryParams,
          section: 'etf',
        },
      }}
      as="/etf"
      name="קרנות סל"
    />
    <ListItem
      selected={!!(section && section.toLowerCase() === 'arbitrage')}
      href={{
        pathname: '/section/arbitrage',
        query: {
          ...baseQueryParams,
          section: 'arbitrage',
        },
      }}
      as="/arbitrage"
      name="ארביטראז'"
    />
    <ListItem
      selected={false}
      href="https://www.themarker.com"
      name="TheMarker"
      color={[ 'neutral', '-1', ]}
    />
  </FelaComponent>
);

NavigationBar.defaultProps = {
  miscStyles: null,
  ...baseQueryParams,
  section: '',
};
export default NavigationBar;
