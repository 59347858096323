/* global document */
// @flow
import React, { useState, useRef, } from 'react';
import { FelaComponent, } from 'react-fela';
import { IconSearch, } from '@haaretz/htz-components';

import type { Node, } from 'react';

import Search from '../Search/Search';
import SearchResults from '../SearchResults/SearchResults';

function SearchBar(): Node {
  const [ query, setQuery, ] = useState('');
  const queryInput: Object = useRef(null);

  const updateQuery = (value = null) => {
    setQuery(value === null ? queryInput.current.value : value);
  };

  const handleClick = (e: Event) => {
    if (queryInput && !queryInput.current.contains(e.target)) {
      document.removeEventListener('click', handleClick);
      updateQuery('');
    }
  };

  return (
    <FelaComponent
      style={({ theme, }) => ({
        // width: '55rem',
        marginStart: '1rem',
        alignSelf: 'center',
        position: 'relative',
        extend: [
          theme.type(-2),
          theme.mq({ until: 'm', }, { width: '27rem', }),
          theme.mq({ from: 'm', until: 'l', }, { width: '40rem', }),
          theme.mq({ from: 'l', }, { width: '55rem', }),
        ],
      })}
    >
      {({ className, }) => (
        <div
          className={className}
        >
          <FelaComponent
            style={({ theme, }) => ({
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.color('neutral', '-7'),
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              paddingStart: '1rem',
              paddingEnd: '1rem',
            })}
          >
            <FelaComponent
              style={({ theme, }) => ({
                backgroundColor: theme.color('neutral', '-7'),
                width: '100%',
                fontWeight: '700',
              })}
            >
              {({ className, }) => (
                <input
                  className={className}
                  type="text"
                  ref={queryInput}
                  onChange={() => updateQuery()}
                  value={query}
                  placeholder="חיפוש ניירות ערך"
                  onFocus={() => {
                    document.addEventListener('click', handleClick);
                  }}
                />
              )}
            </FelaComponent>
            <IconSearch size={3} fill={[ 'neutral', '-2', ]} />
          </FelaComponent>
          {
            query.trim().length > 1
              ? (
                <Search query={query.trim()}>
                  {({ assets, articles, }) => (
                    <FelaComponent
                      style={({ theme, }) => ({
                        position: 'absolute',
                        zIndex: '6',
                        width: '100%',
                        backgroundColor: theme.color('neutral', '-10'),
                      })}
                    >
                      <SearchResults assets={assets} articles={articles} query={query} />
                    </FelaComponent>
                  )}
                </Search>
              )
              : null
          }
        </div>
      )}
    </FelaComponent>
  );
}

export default SearchBar;
