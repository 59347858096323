// @flow
import React from 'react';
import { FelaComponent, } from 'react-fela';
import { algoliaToImage, BlockLink, Grid, GridItem, Image, } from '@haaretz/htz-components';

import type { Node, } from 'react';

type ArticleType = {
  title: string,
  image: string,
  isOdd: boolean,
  url: string,
}

function Article({ url, title, image, isOdd, }: ArticleType): Node {
  return (
    <FelaComponent
      style={({ theme, }) => ({
        backgroundColor: theme.color('neutral', isOdd ? '-10' : '-7'),
        ':hover': {
          backgroundColor: theme.color('neutral', '-6'),
        },
      })}
    >
      {({ className, theme, }) => (
        <div className={className}>
          <BlockLink href={url}>
            <Grid
              gutter={1}
              miscStyles={{
                padding: '1rem',
                marginStart: '0rem',
                marginEnd: '0rem',
              }}
            >
              <GridItem
                width={1 / 5}
                miscStyles={{
                }}
              >
                <Image
                  image={algoliaToImage(image)}
                  imgOptions={{
                    transforms: {
                      width: '100',
                      aspect: 'regular',
                      quality: 'auto',
                    },
                  }}
                />
              </GridItem>
              <GridItem
                width={4 / 5}
                miscStyles={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '700',
                  ...theme.type(-2),
                }}
              >
                {title}
              </GridItem>
            </Grid>
          </BlockLink>
        </div>
      )}
    </FelaComponent>
  );
}

export default Article;
