// @flow
import React from 'react';
import { Query, } from '@haaretz/htz-components';

import type { ChildrenArray, Node, } from 'react';

import searchQuery from './searchQuery.graphql';

type Props = {
  query: string,
  children: ({ assets: any[], articles: any[], }) => (ChildrenArray<Node> | Node),
}

function Search({ query, children, }: Props) {
  return (
    <Query
      query={searchQuery}
      variables={{ query, }}
    >
      {({ loading, error, data, }) => {
        if (loading || error) return null;
        return children({ assets: data.assets, articles: data.articles.hits.tm, });
      }}
    </Query>
  );
}

export default Search;
