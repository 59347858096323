// @flow
import React from 'react';
import { FelaComponent, } from 'react-fela';
import Link from 'next/link';

import type { Node, } from 'react';

function highlightString(string: string, substring: string) {
  const parts = string.split(new RegExp(`(${substring})`, 'gi'));
  return (
    <span>
      { parts.map(part => (
        part.toLowerCase() === substring.toLowerCase()
          ? <strong>{ part }</strong>
          : <span>{ part }</span>
      ))
    }
    </span>
  );
}

type AssetType = {
  id: string,
  type: string,
  name: string,
  isOdd: boolean,
  query: string,
}

function Asset({ id, type, name, isOdd, query, }: AssetType): Node {
  return (
    <FelaComponent
      style={({ theme, }) => ({
        display: 'block',
        backgroundColor: theme.color('neutral', isOdd ? '-7' : '-10'),
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingStart: '1rem',
        paddingEnd: '1rem',
        ':hover': {
          backgroundColor: theme.color('neutral', '-6'),
        },
      })}
    >
      {({ className, }) => (
        <Link
          href={{
            pathname: `/asset/${type}`,
            query: {
              assetId: id,
              section: type,
            },
          }}
          as={`/${type}/${id}`}
        >
          <a className={className}>
            {highlightString(name, query)}
          </a>
        </Link>
      )}
    </FelaComponent>
  );
}

export default Asset;
