import React from 'react';
import { useFela, } from 'react-fela';

// List views

import {
  // General Elements
  ChangeableElementGroup,
  ClickTrackerWrapper,
  Debug,
  ElementGroup,
  Error,
  AdSlot,
  AdSlotPrioritySelector,
  HtmlElement,
  Image,
  ImageGallery,
  List,
  Video,

  // List views
  Bender,
  Farnsworth,
  Leela,
  Zoidberg,
  Clampazzo,

  // icons
  IconFinanceLogo,
  IconTmLoader,

  // utils
  getElementsFactory,
} from '@haaretz/htz-components';

function MastheadLogo() {
  const { theme, } = useFela();
  return (
    <IconFinanceLogo
      color="primary"
      // miscStyles={{ display: 'block', }}
      size={[ { until: 'l', value: 4, }, { from: 'l', value: 5, }, ]}
      miscStyles={{
        marginBottom: [ { from: 's', until: 'l', value: '.5rem', }, ],
        transitionProperty: 'fill, color,  height, width',
        ...theme.getDelay('transition', -1),
        ...theme.getDuration('transition', -1),
        ...theme.getTimingFunction('transition', 'linear'),
        willChange: 'transform',
        // color: [ { until: 's', value: 'white', }, ],
        fill: [ { until: 'l', value: 'transparent', }, { from: 'l', value: [ 'primary', 'base', ], }, ],
      }}
    />
  );
}

const elements = new Map([
  /* Misc components */
  [ 'image', Image, ],
  [ 'htz_image_Image', Image, ],
  // ['com.tm.BlogImage', Image,],
  [ 'imageGalleryTeaser', ImageGallery, ],
  [ 'gallery', ImageGallery, ],
  // [ 'video', Video, ],
  [ 'HtmlElement', HtmlElement, ],
  [ 'StaticElement', HtmlElement, ],
  [ 'htz_list_List', List, ],
  [ 'SideBox', ChangeableElementGroup, ],
  // [ 'com.tm.ElementGroup', ElementGroup, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  [ 'ClickTrackerWrapper', ClickTrackerWrapper, ],
  [ 'error', Error, ],
  [ null, null, ],

  // List views
  [ 'Bender', Bender, ],
  [ 'Farnsworth', Farnsworth, ],
  [ 'Leela', Leela, ],
  [ 'Zoidberg', Zoidberg, ],
  [ 'Clampazzo', Clampazzo, ],

  // icons
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'loaderIcon', IconTmLoader, ],
]);

const getPageElements = getElementsFactory(elements);

export default getPageElements;
