// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  MastheadSearch,
  IconClose,
  IconSearch,
  HtzLink,
  IconFinanceLogo,
  VisuallyHidden,
} from '@haaretz/htz-components';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import Search from '../Search/Search';
import SearchResults from '../SearchResults/SearchResults';


type PropsType = {
  searchInputRef: ?{ current: null | HTMLInputElement, },
  onClose: ?() => void,
};

type MastheadSearchPropsType = {
  buttonMiscStyles: ?StyleProps,
  isMobile: boolean,
  showText: ?boolean,
  rippleColor: ?string,
  searchComponent: ?React.ElementType,
  onClose: ?(boolean => void),
  onOpen: ?(boolean => void),
};

const wrapperStyle = ({ theme, }) => ({
  height: '100%',
  paddingTop: '4rem',
  paddingStart: '4rem',
  paddingEnd: '4rem',
  paddingBottom: '4rem',
  color: theme.color('white'),
  overflowY: 'scroll',
  backgroundColor: theme.color('neutral', '-2'),
});

const searchBoxStyle = ({ theme, }) => ({
  height: '30vh',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  paddingBottom: '4px',
  borderBottomColor: theme.color('white'),
  borderBottomStyle: 'solid',
  borderBottomWidth: '2px',
  marginBottom: '4rem',
  marginTop: '4rem',
});

const closeButtonStyle = {
  outline: 'none',
  minWidth: '6rem',
  minHeight: '6rem',
  position: 'absolute',
  top: '0',
  end: '0',
};

const inputStyle = ({ theme, }) => ({
  width: '100%',
  outline: 'none',
  background: 'inherit',
  ...theme.type(5),
  '::placeholder': {
    color: theme.color('white'),
    opacity: '0.6',
  },
});

MobileSearch.defaultProps = {
  buttonMiscStyles: null,
  isMobile: true,
  showText: null,
  rippleColor: null,
  searchComponent: null,
  onClose: null,
  onOpen: null,
};
function MobileSearch({ ...props }: MastheadSearchPropsType) {
  return <MastheadSearch {...props} searchComponent={SearchDialog} />;
}

SearchDialog.defaultProps = {
  onClose: null,
  searchInputRef: null,
};
export function SearchDialog(props: PropsType) {
  const { css, theme, } = useFela();
  const [ query, setQuery, ] = React.useState('');
  const updateQuery = React.useCallback(evt => {
    setQuery((evt && evt.target) ? evt.target.value : '');
  }, []);
  return (
    <div className={css(wrapperStyle)}>
      <HtzLink href="/" className={css({ display: 'flex', justifyContent: 'center', })}>
        <IconFinanceLogo size={6} aria-hidden />
        <VisuallyHidden>{theme.homepageLogoLink.a11yText}</VisuallyHidden>
      </HtzLink>
      <div className={css(searchBoxStyle)}>
        <button
          type="button"
          size={5}
          className={css(closeButtonStyle)}
          onClick={props.onClose}
        >
          <IconClose size={5} aria-hidden />
          <VisuallyHidden>{theme.headerSearchI18n.a11yTexts.close}</VisuallyHidden>
        </button>
        <div className={css({ flexGrow: 1, })}>
          <input
            ref={props.searchInputRef}
            className={css(inputStyle)}
            placeholder="חיפוש ניירות ערך"
            value={query}
            onChange={updateQuery}
          />
        </div>
        <IconSearch size={7} aria-hidden />
      </div>
      {query.trim().length > 1 ? (
        <Search query={query.trim()}>
          {({ assets, articles, }) => (
            <SearchResults
              assets={assets}
              articles={articles}
              query={query}
              miscStyles={{ color: theme.color('neutral', '-1'), }}
              articlesHeaderStyle={{ color: theme.color('white'), }}
            />
          )}
        </Search>
      ) : null}
    </div>
  );
}

export default MobileSearch;
