// @flow

import { useQuery, } from 'react-apollo';
import {
  ASIDE_SLOT_ARTICLE_QUERY,
  PRE_HEADER_SLOT_QUERY,
  POST_HEADER_SLOT_QUERY,
  MAIN_SLOT_QUERY,
  POST_MAIN_SLOT_QUERY,
  IMMUTABLE_PAGE_ELEMENTS_QUERY,
} from '@haaretz/graphql';
import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import usePreview from './usePreview';
import useAuthorId from './useAuthorId';
import useTagId from './useTagId';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import usePrint from './usePrint';

const { isHTZ, siteNumber, } = checkSiteFromConfig();

type slotNameUnion = 'preHeader' | 'header' | 'postHeader' | 'main' | 'postMain' | 'footer' | 'aside';

const SlotQueryMap = new Map < slotNameUnion, any>([
  [ 'preHeader', PRE_HEADER_SLOT_QUERY, ],
  [ 'header', IMMUTABLE_PAGE_ELEMENTS_QUERY, ],
  [ 'postHeader', POST_HEADER_SLOT_QUERY, ],
  [ 'main', MAIN_SLOT_QUERY, ],
  [ 'postMain', POST_MAIN_SLOT_QUERY, ],
  [ 'footer', IMMUTABLE_PAGE_ELEMENTS_QUERY, ],
  [ 'aside', ASIDE_SLOT_ARTICLE_QUERY, ],
]);

export default function useSlotData(slotName: slotNameUnion) {
  const articleId = useArticleId();
  const { previewId, } = usePreview();
  const authorId = useAuthorId();
  const tagId = useTagId();
  const router = useRouter();
  const { isPrint, } = usePrint();

  const path = router.query.path;

  const SlotQuery = SlotQueryMap.get(slotName);
  const id = articleId || authorId || tagId || previewId;

  let variables = id ? {
    id,
  } : { path, };

  if (slotName === 'header' || slotName === 'footer') {
    variables = { path: siteNumber === 20 ? path : '/', };
  }

  const { data, loading, error, } = useQuery(SlotQuery, {
    fetchPolicy: 'cache-first',
    skip: !slotName || !SlotQuery,
    variables,
  });

  if (!slotName) {
    console.error('SlotName not passed');
    return null;
  }

  if (loading) return null;
  if (error) {
    console.error('Slot error', error);
    return null;
  }

  if (!data) return null;
  if (slotName === 'main') {
    if (articleId && isPrint) {
      return [];
    }

    if (isHTZ && router.pathname === '/newsletter-unsub') {
      const main = [ ...(data?.Page?.slots?.[slotName] || []), ];

      const unsusbscribePageRecommendationsElement = { kind: 'unsubscribePageRecommendations', };

      main.push(unsusbscribePageRecommendationsElement);
      return main;
    }
  }

  return data?.Page?.slots?.[slotName];
}
