// import { aspectRatios, } from '../../../Image/consts';

const defaultImage = {
  url: 'https://gif.haarets.co.il/image/upload/v1557069611/1.7206615.3396785542.png',
  alt: 'הארץ',
};

function toImage(indexValue) {
  let image = null;
  const imageUrl = indexValue || defaultImage;

  if (imageUrl != null) {
    image = {
      photographer: 'none',
      accessibility: '',
      imageType: 'image',
      inputTemplate: 'htz_image_Image',
      contentId: getImageContentId(imageUrl),
      contentName: '',
      kind: 'image',
      files: getImageArray(imageUrl),
    };
  }

  return image;
}

function getImageContentId(indexValue) {
  const contentidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
  const matches = contentidRegex.exec(indexValue.url);
  const contentid = matches?.[0];

  return contentid;
}

function getImageArray(indexValue) {
  return [
    {
      imgName: getImageName(indexValue),
      path: getImageName(indexValue),
      aspects: getImageAspects(indexValue),
    },
  ];
}

function getImageName(indexValue) {
  const polopolyFilenameRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/(.+\.[a-z]{3})/;
  const matches = polopolyFilenameRegex.exec(indexValue.url);
  const imageName = matches?.[2] || null;

  return imageName;
}

function getImageAspects(indexValue) {
  return {
    regular: { height: '', width: '', },
    headline: { height: '', width: '', },
    landscape: { height: '', width: '', },
    square: { height: '', width: '', },
    vertical: { height: '', width: '', },
    belgrade: { height: '', width: '', },
  };
}

export { toImage, };
