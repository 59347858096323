// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import Asset from './Asset';
import Article from './Article';

type Props = {
  assets: any[],
  articles: any[],
  query: string,
  miscStyles: ?StyleProps,
  articlesHeaderStyle: ?StyleProps,
};

SearchResults.defaultProps = {
  miscStyles: null,
  articlesHeaderStyle: null,
};
function SearchResults({ assets, articles, query, miscStyles, articlesHeaderStyle, }: Props): Node {
  const { css, theme, } = useFela();
  return (
    <div
      className={css({
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)',
        extend: [
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <div>
        {assets.map((asset, index) => (
          <Asset key={asset.objectID} {...asset} isOdd={index % 2} query={query} />
        ))}
      </div>
      {
        articles.length
          ? (
            <div
              className={css({
                ...borderTop(2, 1, 'solid', theme.color('neutral', '-4')),
              })}
            >
              <p
                className={css({
                  fontWeight: '700',
                  paddingStart: '1rem',
                  marginTop: '1rem',
                  extend: [
                    theme.type(-3),
                    ...(articlesHeaderStyle
                      ? parseStyleProps(articlesHeaderStyle, theme.mq, theme.type)
                      : []),
                  ],
                })}
              >
                כתבות בנושא
              </p>
              {articles.map((article, index) => (
                <Article key={article.objectID} {...article} isOdd={index % 2} />
              ))}
            </div>
          )
          : null
      }
    </div>
  );
}

export default SearchResults;
